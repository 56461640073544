import {
  Box,
  Button,
  ButtonBase,
  Container,
  createStyles,
  Dialog,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import { useSnackbar } from "notistack";
import React from "react";
import Helmet from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";

import books_magazine from "../images/books_magazine.png";
import conferences from "../images/conferences.png";
import life_science from "../images/life_science.png";
import live_portrait from "../images/live_portrait.png";
import outdoor_signage from "../images/outdoor_signage.png";
import packaged_goods from "../images/packaged_goods.png";
import poster_session from "../images/poster_sessions.png";
import real_estate from "../images/real_estate.png";

// const vimeoPage = "https://vimeo.com/user85185520";

interface VideoInfo {
  title: string;
  playerSrc: string;
  pageSrc: string;
  thumb: string;
  pdf?: string;
}

const videos: VideoInfo[] = [
  {
    title: "Consumer Packaged Goods",
    playerSrc: "https://player.vimeo.com/video/685689623?h=a1796db9fb",
    pageSrc: "https://vimeo.com/685689623",
    thumb: packaged_goods,
  },
  {
    title: "Outdoor Signage",
    playerSrc: "https://player.vimeo.com/video/286614566?h=045a5ed1a7",
    pageSrc: "https://vimeo.com/286614566",
    thumb: outdoor_signage,
    pdf: "signage_guide.pdf",
  },
  {
    title: "Live Portraits",
    playerSrc: "https://player.vimeo.com/video/685690909?h=0ea38cd320",
    pageSrc: "https://vimeo.com/685690909",
    thumb: live_portrait,
  },
  {
    title: "Trade-Shows",
    playerSrc: "https://player.vimeo.com/video/685689959?h=f2a4f4c4d5",
    pageSrc: "https://vimeo.com/685689959",
    thumb: conferences,
  },
  {
    title: "Real Estate",
    playerSrc: "https://player.vimeo.com/video/685690027?h=d8efcb7949",
    pageSrc: "https://vimeo.com/685690027",
    thumb: real_estate,
  },
  {
    title: "Books & Magazines",
    playerSrc: "https://player.vimeo.com/video/752641685?h=9c036c1bca",
    pageSrc: "https://vimeo.com/752641685",
    thumb: books_magazine,
    pdf: "publishing_guide.pdf",
  },
  {
    title: "Conferences",
    playerSrc: "https://player.vimeo.com/video/407677900?h=e98a110603",
    pageSrc: "https://vimeo.com/407677900",
    thumb: poster_session,
  },
  {
    title: "Life Sciences",
    playerSrc: "https://player.vimeo.com/video/685689880?h=30293890f9",
    pageSrc: "https://vimeo.com/685689880",
    thumb: life_science,
    pdf: "/scientific_poster_guide.pdf",
  },
];

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      // margin:
    },
    sectionHeading: {
      marginBottom: theme.spacing(5),
    },
    subhead: {
      fontWeight: "bold",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
    // heroGrid: {
    //   margin: theme.spacing(3, 0),
    // },
    heroVid: {
      width: "100%",
      height: "auto",
    },
    heroBtn: {
      display: "block",
    },
    heroTextBox: {
      // padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    contactBtn: {
      marginTop: theme.spacing(4),
      fontSize: "1.6em",
    },
    wrapper: {
      marginBottom: theme.spacing(1),
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    vidThumb: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      transition: theme.transitions.create(["box-shadow", "transform"], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      "&:hover": {
        transform: "scale(1.03, 1.04)",
        boxShadow: theme.shadows[8],
      },
    },

    card: {
      maxWidth: 500,
      margin: "32px auto",
    },
    textHighlight: {
      color: "#ff0000",
    },
  })
);

const Enterprise: React.FC = () => {
  const styles = useStyles();
  const [email, setEmail] = React.useState("");
  const [currVid, setCurrVid] = React.useState<VideoInfo | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<{ email: string }> = async data => {
    try {
      await axios.put("/api/v1/mailchimp_list/subscribe", { email });
      enqueueSnackbar("Subscribed!", { variant: "success" });
    } catch (reason) {
      enqueueSnackbar("An error has occured.", { variant: "error" });
    }
  };

  return (
    <>
      <Helmet
        title="Spear XR"
        meta={[
          // {
          //   name: "description",
          //   content:
          //     "The future of presentations with augmented reality. Turn regular posters and presentations into engaging augmented reality experiences in minutes.",
          // },
          {
            name: "keywords",
            content:
              "augmented, reality, companies, enterprise, apps, healthcare, publishing",
          },
        ]}
      />
      <Dialog
        open={Boolean(currVid)}
        maxWidth={false}
        onClose={() => setCurrVid(null)}
      >
        {currVid && (
          <iframe
            src={currVid.playerSrc}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </Dialog>

      <Container
        maxWidth="lg"
        // style={{ overflowX: "hidden", margin: "24px auto 0" }}
      >
        <Grid
          /* className={styles.heroGrid} */ container
          spacing={2}
          style={{ marginTop: 20 }}
        >
          <Grid className={styles.heroTextBox} item sm={12} md={7}>
            <video
              className={styles.heroVid}
              autoPlay
              muted
              loop
              src="/Compilation_Shorter.mp4"
              // controls
            />
          </Grid>
          <Grid className={styles.heroTextBox} item sm={12} md={5}>
            {/* <div > */}
            {/* <Typography variant="h4">SpearXR</Typography>
              <Typography variant="h4" paragraph>
                Enterprise Solutions
              </Typography> */}
            <StaticImage
              src="../images/spearxr_logo_light.svg"
              alt="SpearXR"
              layout="fixed"
              height={160}
              // width={220}
            />
            <Typography
              variant="h5"
              paragraph
              color="textSecondary"
              align="center"
            >
              Turnkey emerging tech solutions to enhance your business
              offerings.
            </Typography>
            <div>
              <Button
                component="a"
                href="mailto:hello@spearxr.com"
                color="primary"
                variant="contained"
                size="large"
                // style={{ fontSize: "1.7em" }}
              >
                Contact Us
              </Button>
            </div>
            {/* </div> */}
          </Grid>
        </Grid>

        <Box id="case-studies" pt="64px" mt="-64px">
          <Divider className={styles.divider} />
        </Box>

        <Typography
          className={styles.sectionHeading}
          variant="h4"
          align="center"
        >
          Case Studies
        </Typography>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          // alignItems="center"
          // style={{ marginBottom: 32 }}
        >
          {videos.map((v, i) => (
            <Grid
              className={styles.gridItem}
              key={i}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <ButtonBase
                className={styles.vidThumb}
                onClick={() => setCurrVid(v)}
                // style={{ width: "100%", height: 200 }}
              >
                <img
                  src={v.thumb}
                  alt={v.title}
                  style={{ maxWidth: "100%", maxHeight: 200, borderRadius: 8 }}
                />
              </ButtonBase>
              <Typography
                className={styles.subhead}
                variant="h6"
                align="center"
              >
                {v.title}
              </Typography>
              {v.pdf && (
                <Link href={v.pdf} target="_blank">
                  PDF Guide
                </Link>
              )}
            </Grid>
          ))}
        </Grid>

        <Box id="our-services" pt="64px" mt="-64px">
          <Divider className={styles.divider} />
        </Box>

        <Typography
          className={styles.sectionHeading}
          variant="h4"
          align="center"
        >
          Our Services
        </Typography>
        <Grid
          id="services"
          container
          spacing={4}
          justifyContent="center"
          // alignItems="center"
          style={{ marginBottom: 32 }}
        >
          <Grid item md={12} lg={4}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Custom Apps
            </Typography>
            <Typography>
              We create custom AR apps tailored to your specific needs. We start
              with a discovery phase to thoroughly understand your requirements
              and goals, and then build to your specifications. Pick and choose
              from the capabilities below to build your dream app.
            </Typography>
          </Grid>
          <Grid item md={12} lg={4}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Custom Content Creation
            </Typography>
            <Typography>
              Love our platform but don't have time to make the perfect
              presentation? Let us help. Our design team has deep expertise in
              producing engaging, impactful, and visually stunning
              presentations. Turn any company's collateral into professional AR
              enterprise presentations.
            </Typography>
          </Grid>
          <Grid item md={12} lg={4}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              XRPlatform Scalable Backend
            </Typography>
            <Typography>
              Shorten development time of your AR/VR app by integrating a robust
              scalable backend that includes computer-vision, geo-fencing,
              payment modules, team/user management, experience creation, and
              many more.
            </Typography>
          </Grid>
          <Grid item md={12} lg={4}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              White Label
            </Typography>
            <Typography>
              White labeling allows you to put your brand name on the SpearXR
              app and present it as your own. We incorporate your distinctive
              branding attributes such as logos, language, colors, and
              enable/disable existing features.
            </Typography>
          </Grid>
          <Grid item md={12} lg={4}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Integrated Business Solutions
            </Typography>
            <Typography>
              Creation of interactive apps that combine webAR, webVR, AI,
              interactive web 3D and business logic to solve business goals.
            </Typography>
          </Grid>
          <Grid item md={12} lg={4}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Commercial Use
            </Typography>
            <Typography>
              Experiences made with the SpearXR Platform are perfect for
              commercial use including advertising, promotion, and
              merchandising. With a commercial license, you can invite unlimited
              contributors from your organization to design pay per view AR
              content.
            </Typography>
          </Grid>
        </Grid>

        <Divider className={styles.divider} variant="fullWidth" />

        <Typography
          className={styles.sectionHeading}
          variant="h4"
          align="center"
        >
          Capabilities
        </Typography>

        <Grid id="custom-app-capabilities" container spacing={4}>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Online Experience Creation Studio
            </Typography>
            <Typography>
              Create complex 3D experiences using our online studio. Experiences
              can be viewed in a mobile app, in browser or embedded on websites.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Interactive Experiences
            </Typography>
            <Typography>Calls to action, quizzes, rewards and more.</Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Admin Dashboard
            </Typography>
            <Typography>
              Create and manage content, users, experiences. Update the app
              content in real time.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Markerless Experiences
            </Typography>
            <Typography>
              Experiences can be viewed anywhere (without a marker), such as
              placing products in your living room.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Robust Scalable Backend
            </Typography>
            <Typography>
              Using our proprietary XRPlatform backend, shorten development time
              of AR/VR apps and scale using serverless technology, with CDN
              content distribution.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Marker Based Experiences
            </Typography>
            <Typography>
              Trigger experiences when you point your device directly at content
              such as books, magazines, billboards, or physical objects.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              SDK Capability
            </Typography>
            <Typography>
              Ability to integrate functionality into your existing app,
              eliminating the need for an additional app.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Meetings (Collaborate in Real-Time)
            </Typography>
            <Typography>
              Allows multiple users to be present in the same experience, see
              each other’s avatars, and communicate with each other.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Analytics
            </Typography>
            <Typography>
              Custom dashboards and daily email reports about behavior of your
              user base.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Live Portrait
            </Typography>
            <Typography>Ability to animate still portraits.</Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              WebAR
            </Typography>
            <Typography>
              No app or downloads are required. Experiences are accessed with
              the camera on your device.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Gamification
            </Typography>
            <Typography>
              Reward your audience with collectible AR content.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Machine Learning
            </Typography>
            <Typography>
              Analyze your user’s emotional state using video, voice or text,
              and create custom responses.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Direct and Group Messaging
            </Typography>
            <Typography>
              Allow your app community to communicate with each other in-app
              messaging.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              NFT
            </Typography>
            <Typography>
              Curate, design and view your NFT galleries in AR space.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Voice Chat
            </Typography>
            <Typography>
              High quality voice chat integrated into AR experiences.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Indoor Navigation
            </Typography>
            <Typography>
              Patented indoor and outdoor navigation using signage.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Streaming 4K Videos
            </Typography>
            <Typography>Adaptive bitrate and CDN distribution.</Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              E-commerce
            </Typography>
            <Typography>
              Monetize your community using in-app purchases, shopping carts and
              subscriptions.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Notifications
            </Typography>
            <Typography>
              Create custom notification based on users activity, timing, or on
              the entire app community.
            </Typography>
          </Grid>

          <Grid item sm={12} lg={6}>
            <Typography className={styles.subhead} variant="h6" gutterBottom>
              Social Media Feed
            </Typography>
            <Typography>
              Allow your users to share their media into a real time app feed.
            </Typography>
          </Grid>
        </Grid>

        <Divider
          className={styles.divider}
          // style={{ backgroundColor: "transparent" }}
          variant="fullWidth"
        />

        <Typography
          variant="h4"
          align="center"
          paragraph
          className={styles.sectionHeading}
        >
          Our Flag Product
        </Typography>
        <Grid container spacing={4} style={{ marginBottom: 32 }}>
          <Grid
            item
            sm={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography paragraph align="center">
              Post Reality is a web based SaaS product that enables creation and
              authoring of augmented reality experiences. Manage large teams and
              communities using post reality.
            </Typography>
            <div style={{ margin: "0 auto" }}>
              <Button
                className={styles.contactBtn}
                component="a"
                href="https://postreality.io"
                target="_blank"
                color="primary"
                variant="contained"
                // size="large"
              >
                Post Reality
              </Button>
            </div>
          </Grid>
          <Grid /* className={styles.gridItem} */ item sm={12} md={6}>
            <StaticImage
              src="../images/carousel_1.png"
              alt="Post Reality"
              // layout="fullWidth"
              // width={400}
            />
            {/* <img
              className="idea"
              src={lightbulb}
              alt="idea"
              style={{ maxWidth: "100%", height: "auto" }}
            /> */}
          </Grid>
        </Grid>

        {/* <Card className={styles.card} elevation={5}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img className="idea" src={lightbulb} width={100} alt="idea" />
              <Box ml={2}>
                <Typography gutterBottom>
                  Let the experts at Post Reality guide you through the process
                  from concept to execution.
                </Typography>
                <Button
                  component="a"
                  href="mailto:hello@postreality.io"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </CardContent>
          <Divider style={{ margin: 0 }} />
          <Box
            px={4}
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>Join our mailing list</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant="outlined"
                size="small"
                type="email"
                label="Email"
                onChange={ev => setEmail(ev.target.value)}
                helperText={errors.email?.message}
                error={Boolean(errors.email)}
                inputProps={{
                  defaultValue: "",
                  ...register("email", {
                    required: "Valid email required.",
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Valid email required",
                    },
                  }),
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" size="small" color="primary">
                        <Icon path={mdiSend} size={1} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        </Card> */}
      </Container>
    </>
  );
};

export default Enterprise;
